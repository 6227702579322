"use client";
import { generatedResources } from "@/app/i18n/src";
import { ErrorArtWork } from "@/components/common/error/error-art-work";

const resources = generatedResources["fr-FR"];

export const ErrorPage = () => {
  return (
    <div className="flex h-full flex-col items-center gap-y-1 pt-24 font-sans">
      <div>
        <ErrorArtWork className=" h-[150px] sm:h-[220px] " />
      </div>
      <div className=" text-18 font-bold sm:text-[26px]">
        {resources.notFoundPageTitle()}
      </div>
      <div className="text-16 sm:text-18">
        {resources.notFoundPageSubtitle()}
      </div>
    </div>
  );
};
